import React, { useState, useEffect } from "react";
import styles from "./AgendaItem.module.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AgendaItem = ({
  numeration,
  first = false,
  last = false,
  reversed = false,
  title,
  text,
  date,
  large,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const variants = {
    hidden: {
      opacity: 0,
      x: reversed ? 100 : -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
  };

  const [randomNumber, setRandomNumber] = useState(3);

  useEffect(() => {
    const generateRandomNumber = () => {
      const min = 1;
      const max = 5;
      const random = Math.floor(Math.random() * (max - min + 1)) + min;
      setRandomNumber(random);
    };
    generateRandomNumber();
    // const timer = setTimeout(() => {
    //   generateRandomNumber();
    // }, 2000);
  }, []);

  return (
    <motion.div
      className={`${styles.wrapper} ${reversed ? `${styles.reverse}` : ""} ${
        large ? `${styles.lrgWrapper}` : ""
      } `}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={variants}
    >
      <div
        className={`${styles.field} ${large ? `${styles.lrgField}` : ""} `}
        ref={ref}
      >
        <h3 className={styles.date}>{date}</h3>
        <h3 className={styles.title}>{title}</h3>
        <h4 className={large ? `${styles.smallTxt}` : ""}>{text}</h4>
      </div>

      <div
        className={`${styles.ringWrapper} ${
          reversed ? "" : styles.revRingWrapper
        }  ${large ? styles.lrgRingWrapper : ""} ${
          large && !reversed ? styles.revLrgRingWrapper : ""
        }`}
      >
        <div
          className={`${styles.purpleRing} ${
            numeration == randomNumber ? styles.flickerNonStop : ""
          }  `}
        >
          <div
            className={`${styles.transRing} ${
              numeration == randomNumber ? styles.flickerNonStop : ""
            }  `}
          ></div>
          <div className={styles.greenDot}></div>
          {/* MOBILE LINIJA */}
          <svg
            className={`${styles.notVisible} ${
              first ? styles.firstLineMob : ""
            }  ${
              reversed ? styles.reversedLineMob : styles.notReversedLineMob
            } ${last ? styles.lastLineMob : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            width="160"
            height="16"
            viewBox="0 0 64 10"
            fill="none"
          >
            <path
              d="M59 5L5 5"
              stroke="white"
              stroke-width="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {/* PC LINIJA */}
          <svg
            className={`${styles.visible}  ${
              reversed ? styles.reversedLine : styles.notReversedLine
            } ${last ? styles.lastLine : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="400"
            viewBox="0 0 16 173"
            fill="none"
          >
            <path
              d="M8 165L8 -165"
              stroke="white"
              stroke-width="15"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-dasharray="30 50"
            />
          </svg>

          {first && (
            <svg
              className={`${styles.firstLine} ${styles.visible}`}
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="260"
              viewBox="0 0 16 173"
              fill="none"
            >
              <path
                d="M8 165L8 -165"
                stroke="white"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="30 50"
              />
            </svg>
          )}
          {last && (
            <svg
              className={`${styles.notVisible}  ${
                reversed ? styles.reversedLineMob : styles.notReversedLineMob
              }  ${last ? styles.lastLineMobile : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="160"
              height="16"
              viewBox="0 0 64 10"
              fill="none"
            >
              <path
                d="M59 5L5 5"
                stroke="white"
                stroke-width="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          {/* {first &&
              <svg  className={`${styles.notVisible}  ${reversed ? styles.reversedLineMob : styles.notReversedLineMob} ${last ? styles.lastLine : ''}`}
              xmlns="http://www.w3.org/2000/svg" width="160" height="16" viewBox="0 0 64 10" fill="none">
                <path d="M59 5L5 5" stroke="white" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            } */}
        </div>
      </div>
    </motion.div>
  );
};

export default AgendaItem;
