import React, { forwardRef } from 'react';
import PageSection from '../PageSection/PageSection';
import WorkshopsTest from '../Workshops/WorkshopsTest';
import JelicaStanojevic from '../../components/Workshops/img/JelicaStanojevic.png';
import BorisVajagic from '../../components/Workshops/img/BorisVajagic.png';
import Front from '../../components/Workshops/img/front.png';
import Git from '../../components/Workshops/img/git.png';
import person from '../../components/Workshops/img/person.jpg';

const WorkshopsSection = ({}, ref) => {
  return (
    <PageSection heading='Radionice' ref={ref}>
      <WorkshopsTest
        first
        main_heading='FRONT END'
        picture={Front}
        lecturer='Marko Nikolić'
        paragraph='Front end je zaslužan za kreiranje korisničkog interfejsa veb-sajta, kao i samog doživljaja koji posetilac ima na stranici. Na ovoj radionici će učesnici steći znanja koja će im omogućiti da kreiraju vizuelni deo veb-sajta i svoju ideju sprovedu u delo.'
      ></WorkshopsTest>

      <WorkshopsTest
        main_heading='BACK END'
        picture={JelicaStanojevic}
        lecturer='Jelica Stanojević'
        paragraph='Back end podrazumeva serversku izradu koja se fokusira na logiku i funkcionalnost veb-sajta. Odgovoran je za skladištenje, čuvanje i organizovanje podataka, te samim tim omogućava korišćenje veb-sajta bez poteškoća.'
      ></WorkshopsTest>

      <WorkshopsTest
        main_heading='GIT'
        picture={Git}
        lecturer='Stefan Jovanović'
        paragraph='Git je sistem za kontrolu verzija koda i kao takav, značajan je za saradnju pri pisanju istog. Na ovoj radionici učesnici će naučiti najlakši način za programiranje u timu, što će im značajno pomoći u daljem radu.'
      ></WorkshopsTest>

      <WorkshopsTest
        main_heading='KAKO PREDSTAVITI IDEJU'
        picture={BorisVajagic}
        lecturer='Boris Vajagić'
        paragraph='Kako je prezentovanje sastavni deo takmičenja, veoma je važno da učesnici prenesu ideju svog rešenja na pravi način. Veštine potrebne da to urade steći će upravo na ovoj radionici posvećenoj značajnosti pravilnog izlaganja ideja.'
        last
      ></WorkshopsTest>
    </PageSection>
  );
};

export default forwardRef(WorkshopsSection);
