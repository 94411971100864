import React from 'react';
import styles from './Hero.module.css';
import logo from '../../img/hzs_logo.svg';
import { useNavigate } from 'react-router-dom';

import Button from '../Button/Button';
import { isApplicationOpened } from '../../lib/utils/dates';

const Hero = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/prijava');
  };

  return (
    <section className={`container ${styles.heroSection}`}>
      <div className={styles.textButton}>
        <h1 className={styles.naslov}> HAKATON ZA SREDNJOŠKOLCE</h1>
        <Button
          color='purple'
          onClick={onClick}
          disabled={!isApplicationOpened()}
        >
          Prijave su zatvorene
        </Button>
      </div>
      <img src={logo} alt='Logo' className={styles.logo} />
    </section>
  );
};

export default Hero;
