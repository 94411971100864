import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Motivation.module.css';
import Button from '../Button/Button';
import { isApplicationOpened } from '../../lib/utils/dates';

const Motivation = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/prijava');
  };

  return (
    <section className={`container ${styles.wrapper}`}>
      <div className={styles.motivationSection}>
        <div className={styles.textboxContainer}>
          <div className={styles.textbox}>
            <h4 className={styles.heading}>Čekamo vašu prijavu</h4>
            Hakaton za srednjoškolce nudi jedinstvenu priliku da zaronite u
            fascinantni svet programiranja i IT sektora. Ovo je vaša šansa da
            usavršite prezentacione sposobnosti, steknete novo znanje i
            dragoceno iskustvo. Osim toga, imaćete priliku da upoznate osobe
            koje dele vaša intersovanja i uspostavite nova vredna prijateljstva.
            Prijavite se za uzbudljivu, neprekidnu avanturu koja traje 24 sata.
            Jedva čekamo vašu prijavu!
          </div>
          <Button
            onClick={onClick}
            disabled={!isApplicationOpened()}
            color='secondary'
            border
          >
            Prijave su zatvorene
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Motivation;
