import React, { useRef, useState, useEffect, forwardRef } from 'react';
import PageSection from '../PageSection/PageSection';
import styles from './Agenda.module.css';
import AgendaItem from '../AgendaItem/AgendaItem';

const Agenda = ({}, agendaRef) => {
  return (
    <PageSection ref={agendaRef} heading={'Agenda'} hideOverflowX hideOverflowY>
      <div id='agenda-container' className={` ${styles.agendaContainer}`}>
        <div className={styles.purpleRing}>
          <div className={styles.transRing}></div>
          <div className={styles.greenDot}></div>
          <div className={styles.greenDot2}></div>
        </div>

        <AgendaItem
          numeration={1}
          first={true}
          date={'2.11.2023.'}
          title={'Otvaranje prijava'}
          text={'Okupi tim, popunite formu i prijavite se za Hakaton!'}
        />
        <AgendaItem
          numeration={2}
          reversed={true}
          date={'16.11.2023.'}
          title={'Zatvaranje prijava'}
          text={
            'Nakon zatvaranja, sve prijave će biti pregledane i timovi će dobiti mejl koji sadrži informacije o narednim koracima.'
          }
        />
        <AgendaItem
          numeration={3}
          date={'21.11.2023.'}
          title={'Onlajn test'}
          large={true}
          text={
            'Sledeći korak je onlajn test koji proverava informatičko znanje. Timovi sa najboljim rezultatima na testu prolaze dalje i ostaje im još samo jedan zadatak pre nego što dođu do finala.'
          }
        />
        <AgendaItem
          numeration={4}
          reversed={true}
          date={'25.11. i 26.11.2023.'}
          title={'Radionice'}
          text={
            'Pre poslednjeg zadatka, timove očekuju radionice koje će im pomoći da steknu korisna znanja za njihov dalji rad.'
          }
        />
        <AgendaItem
          numeration={5}
          // last={true}
          title={'Domaći zadatak'}
          date={'30.11.2023.'}
          text={
            'Poslednji zadatak pred finale je domaći u vidu projektnog rada. Deset timova koji su se najbolje pokazali prolaze u finale.'
          }
        />
        <AgendaItem
          numeration={6}
          last={true}
          reversed={true}
          large={true}
          title={'Hakaton'}
          date={'9.12. i 10.12.'}
          text={
            'Na samom takmičenju biće predstavljena tema hakatona, nakon čega timovi imaju 24 časa da osmisle i razviju odgovarajuće softversko rešenje. Timovi potom prezentuju svoje rešenje stručnom žiriju koji će proglasiti pobednika!'
          }
        />
        <div className={styles.purpleRing}>
          <div className={styles.transRing}></div>
          <div className={styles.greenDot}></div>
          <div className={styles.greenDot2}></div>
        </div>
      </div>
    </PageSection>
  );
};

export default forwardRef(Agenda);
