import React, { forwardRef } from 'react';
import styles from '../Menu/Menu.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import PageSection from '../PageSection/PageSection';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Pravilnik from '../../pdf/Pravilnik.pdf';

function Menu(
  { setShowMenu },
  { aboutRef, agendaRef, workshopsRef, faqRef, partnersRef }
) {
  const location = useLocation();
  const navigate = useNavigate();
  function handleClick(ref) {
    location.pathname !== '/' && navigate('/');
    setShowMenu(false);
    ref.current?.scrollIntoView();
  }
  return (
    <motion.div
      className={styles.sideMenu}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <nav>
        <ul>
          <li>
            <a onClick={() => handleClick(aboutRef)}>O takmičenju</a>
          </li>
          <li>
            <a onClick={() => handleClick(agendaRef)}>Agenda</a>
          </li>
          <li>
            <a onClick={() => handleClick(workshopsRef)}>Radionice</a>
          </li>
          <li>
            <a onClick={() => handleClick(faqRef)}>Pitanja</a>
          </li>
          <li>
            <a onClick={() => handleClick(partnersRef)}>Partneri</a>
          </li>
          <li>
            <a
              href={Pravilnik}
              target='_blank'
              onClick={() => setShowMenu(false)}
            >
              Pravilnik
            </a>
          </li>
          {/* <li>
            <Link
              onClick={() => setShowMenu(false)}
              style={{ color: 'var(--green)' }}
              to='prijava'
            >
              Prijavi tim
            </Link>
          </li> */}
        </ul>
      </nav>
    </motion.div>
  );
}

export default forwardRef(Menu);
