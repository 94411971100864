import PageSection from '../PageSection/PageSection';
import styles from './AboutCompetition.module.css';
import { forwardRef } from 'react';
import building from './building.png';
import rocket from './rocket.png';
import person from './user.png';

import Pravilnik from '../../pdf/Pravilnik.pdf';

function AboutCompetition({}, aboutRef) {
  return (
    <PageSection ref={aboutRef} heading='O takmičenju'>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.experianceFull}>
          <div className={styles.about} id='container'>
            <div className={styles.gradientt}>
              <div className={styles.bigBox}>
                <p>
                  Hakaton za srednjoškolce, koji već šestu godinu zaredom
                  organizuje Udruženje studenata informatike FONIS na Fakultetu
                  organizacionih nauka, predstavlja programersko takmičenje
                  namenjeno svim srednjoškolcima u Republici Srbiji.{' '}
                </p>
                <p>
                  {' '}
                  Učesnici se okupljaju u timovima od tri ili četiri člana i
                  dobijaju zadatak da u roku od 24 sata kreiraju softversko
                  rešenje na temu koju saznaju na sam dan takmičenja. Tokom ovog
                  perioda, učesnici imaju podršku mentora koji usmeravaju svoje
                  timove i pomažu im da reše nedoumice.
                </p>
                <p>
                  Nakon isteka vremena, stručni žiri ocenjuje sve timove i
                  donosi odluku o tome koja tri tima su predstavila najbolje
                  rešenje, a njihovim članovima se dodeljuju vredne nagrade.
                </p>
                <p>
                  Pre početka takmičenja, organizuju se radionice koje pokrivaju
                  različite oblasti potrebne za učešće, uključujući front end,
                  back end, git i veštine prezentovanja ideje. Ove radionice su
                  deo procesa selekcije takmičara i pružaju srednjoškolcima
                  priliku da unaprede svoja znanja i veštine u ovim oblastima.
                </p>
                <p>
                  Za više informacija pročitajte{' '}
                  <a href={Pravilnik} target='_blank'>
                    pravilnik.
                  </a>
                </p>
              </div>
            </div>

            <div className={styles.down} id='downContainer'>
              <div className={styles.boxesContainer}>
                <div className={styles.box} id='leftBox'>
                  <div className={styles.icon}>
                    <img src={rocket} />
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.content}>
                    <p>500+ prijavljenih timova</p>
                  </div>
                </div>
              </div>

              <div className={styles.boxesContainer}>
                <div className={styles.box} id='middleBox'>
                  <div className={styles.icon}>
                    <img src={building} />
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.content}>
                    <p>30+ različitih gradova</p>
                  </div>
                </div>
              </div>

              <div className={styles.boxesContainer}>
                <div className={styles.box} id='rightBox'>
                  <div className={styles.icon}>
                    <img src={person} />
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.content}>
                    <p>2000+ takmičara</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
}

export default forwardRef(AboutCompetition);
