import styles from './Experiance.module.css';
import student from './AleksandarNedeljkovic.jpg';
import student2 from './MihailoMatovic.jpg';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PageSection from '../PageSection/PageSection';

import { v4 as uuidv4 } from 'uuid';

const sliderImages = require.context('./sliderImg', true);
const sliderImageList = sliderImages.keys().map((image) => sliderImages(image));

export const Experiance = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <PageSection heading='Iskustvo'>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.experianceFull}>
          <div className={styles.box}>
            <div className={styles.experianceContainer}>
              <div>
                <p>
                  Zaista mi je bila velika čast da učestvujem u jednom veoma
                  zanimljivom takmičenju. Osim što sam se dobro zabavio, naučio
                  nešto novo i osvojio nagradu, upoznao sam mnogo ljudi koji su
                  mi zaista postali dragi. Hakaton za srednjoškolce je nešto što
                  svaki srednjoškolac treba da doživi i zaista savetujem da ne
                  čekaju mnogo, već da popune prijave i spreme se za nešto
                  neverovatno.
                </p>
              </div>
              <div className={styles.experianceInfo}>
                <div className={styles.image}>
                  <img src={student} alt='' className={styles.imageStyle} />
                </div>
                <div className={styles.descriptionContainer}>
                  <p>Aleksandar Nedeljković</p>
                  <hr className={styles.hr} />
                  <p> </p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.box}>
            <div className={styles.experianceContainer}>
              <div>
                <p>
                  Hakaton za srednjoškolce je veoma zanimljivo iskustvo i nešto
                  što bih preporučio srednjoškolcima da ne propuštaju. I za one
                  koji imaju predznanje ali i za one koji ga nemaju, pruža se
                  prilika da testiraju svoje sposobnosti. Veoma je zanimljivo
                  kada imate tako kratko vreme da sa drugarima smislite nešto
                  što se može realizovati.
                </p>
              </div>
              <div className={styles.experianceInfo}>
                <div className={styles.image}>
                  <img src={student2} alt='' className={styles.imageStyle} />
                </div>
                <div className={styles.descriptionContainer}>
                  <p>Mihailo Matović</p>
                  <hr className={styles.hr} />
                  <p> </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='carousel-container'>
          <Slider {...settings}>
            {sliderImageList.map((image, i) => (
              <div className={styles.imageSingle}>
                <img
                  src={image}
                  key={uuidv4}
                  alt=''
                  className={styles.imageSingleStyle}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </PageSection>
  );
};
