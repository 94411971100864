import React, { useRef, useState, useEffect } from 'react';
import styles from './FormSection.module.css';
import { useFormik, useFormikContext } from 'formik';
import PageSection from '../PageSection/PageSection';
import { Field, Form, Formik, FormikProps } from 'formik';
import FormInput from '../FormInput/FormInput';
import { formSchema } from '../../schemas/formSchema';
import TextArea from '../TextArea/TextArea';
import Button from '../Button/Button';
import Select from '../Select/Select';
import PopUp from '../PopUp/PopUp';
import axios from 'axios';
import { apiURL } from '../../utils/api/url';
import { IoClose } from 'react-icons/io5';
import { AnimatePresence, motion } from 'framer-motion';
import { BarLoader } from 'react-spinners';

import Pravilnik from '../../pdf/Pravilnik.pdf';

const FormSection = () => {
  const yearOfStudy = [
    { value: 'i', name: 'I razred' },
    { value: 'ii', name: 'II razred' },
    { value: 'iii', name: 'III razred' },
    { value: 'iv', name: 'IV razred' },
  ];
  const formikRef = useRef();
  const [issubmitting, setIsSubmitting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [forth, setForth] = useState(false);
  const [isError, setIsError] = useState(false);
  const [sources, setSources] = useState([]);
  const [showInfo, setShowInfo] = useState(true);

  useEffect(() => {
    if (!forth) {
      formikRef.current.setFieldValue('fourthMember', null);
    } else {
      formikRef.current.setFieldValue('fourthMember', {
        name: '',
        city: '',
        school: '',
        yearOfStudy: 'i',
        phoneNumber: '',
        email: '',
      });
    }
    formikRef.current.setFieldValue('fourth', forth);
  }, [forth]);

  const handleSelectSrc = (source, checked) => {
    if (checked && !sources.includes(source)) {
      setSources([...sources, source]);
    } else setSources(sources.filter((item) => item !== source));
  };

  useEffect(() => {
    formikRef.current.setFieldValue('leadSource', sources);
    // console.log(sources);
  }, [sources]);

  return (
    <PageSection heading={'Forma za prijavu'}>
      <div className={`${styles.formWrapper} container`}>
        <AnimatePresence mode='wait'>
          <motion.div
            style={{ display: showInfo ? 'block' : 'none' }}
            key={'wrapperInfo'}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.info}
            layout
          >
            <div
              className={styles.closeWrapper}
              onClick={() => setShowInfo(false)}
            >
              <IoClose />
            </div>
            <p>
              Prvi prijavljen član biće smatran kapitenom tima i očekivaće se od
              njega da bude ažuran i odgovara na sve mejlove i dodatne
              informacije o takmičenju!
            </p>
          </motion.div>
        </AnimatePresence>
        <motion.div key='formWrapper' layout>
          <Formik
            innerRef={formikRef}
            validationSchema={formSchema}
            initialValues={{
              firstMember: {
                name: '',
                city: '',
                school: '',
                yearOfStudy: 'i',
                phoneNumber: '',
                email: '',
              },
              secondMember: {
                name: '',
                city: '',
                school: '',
                yearOfStudy: 'i',
                phoneNumber: '',
                email: '',
              },
              thirdMember: {
                name: '',
                city: '',
                school: '',
                yearOfStudy: 'i',
                phoneNumber: '',
                email: '',
              },
              fourthMember: null,
              teamName: '',
              leadSource: [],
              experience: '',
              technologies: '',
              whyYou: '',
              situational: '',
              goals: '',
              teamMembersDescription: '',
            }}
            onSubmit={async (values) => {
              // console.log(sources);
              setIsSubmitting(true);
              try {
                await axios.post(`${apiURL}/api/applications`, values);
                console.log(values);
                setIsComplete(true);
              } catch (error) {
                setIsError(true);
                console.log(error);
              }
            }}
          >
            {({ setFieldValue, isValid }) => (
              <Form className={styles.forma}>
                <h3 className={styles.clanoviLabel}>1. član</h3>
                <FormInput
                  name='firstMember.name'
                  type='text'
                  placeholder='Ime i prezime'
                />
                <FormInput
                  name='firstMember.city'
                  type='text'
                  placeholder='Grad'
                />
                <FormInput
                  name='firstMember.school'
                  type='text'
                  placeholder='Škola'
                />
                <Select
                  onChange={({ value }) =>
                    setFieldValue('firstMember.yearOfStudy', value)
                  }
                  items={yearOfStudy}
                  fullWidth
                />
                <FormInput
                  name='firstMember.phoneNumber'
                  type='text'
                  placeholder='Broj telefona'
                />
                <FormInput
                  name='firstMember.email'
                  type='email'
                  placeholder='Mejl'
                />

                <h3 className={styles.clanoviLabel}>2. član</h3>
                <FormInput
                  name='secondMember.name'
                  type='text'
                  placeholder='Ime i prezime'
                />
                <FormInput
                  name='secondMember.city'
                  type='text'
                  placeholder='Grad'
                />
                <FormInput
                  name='secondMember.school'
                  type='text'
                  placeholder='Škola'
                />
                <Select
                  onChange={({ value }) =>
                    setFieldValue('secondMember.yearOfStudy', value)
                  }
                  name={'secondMember.yearOfStudy'}
                  items={yearOfStudy}
                  fullWidth
                />
                <FormInput
                  name='secondMember.phoneNumber'
                  type='text'
                  placeholder='Broj telefona'
                />
                <FormInput
                  name='secondMember.email'
                  type='email'
                  placeholder='Mejl'
                />

                <h3 className={styles.clanoviLabel}>3. član</h3>
                <FormInput
                  name='thirdMember.name'
                  type='text'
                  placeholder='Ime i prezime'
                />
                <FormInput
                  name='thirdMember.city'
                  type='text'
                  placeholder='Grad'
                />
                <FormInput
                  name='thirdMember.school'
                  type='text'
                  placeholder='Škola'
                />
                <Select
                  onChange={({ value }) =>
                    setFieldValue('thirdMember.yearOfStudy', value)
                  }
                  name={'thirdMember.yearOfStudy'}
                  items={yearOfStudy}
                  fullWidth
                />
                <FormInput
                  name='thirdMember.phoneNumber'
                  type='text'
                  placeholder='Broj telefona'
                />
                <FormInput
                  name='thirdMember.email'
                  type='email'
                  placeholder='Mejl'
                />
                <div className={styles.forthMemberLabel}>
                  <input
                    type='checkbox'
                    name='fourth'
                    checked={forth}
                    onChange={() => setForth(!forth)}
                  />
                  <h3>4. član (opciono) </h3>
                </div>

                {forth && (
                  <div
                    className={`${styles.forma} ${
                      forth ? `${styles.fadeInStart}` : `${styles.fadeInEnd}`
                    }`}
                  >
                    <FormInput
                      name='fourthMember.name'
                      type='text'
                      placeholder='Ime i prezime'
                    />
                    <FormInput
                      name='fourthMember.city'
                      type='text'
                      placeholder='Grad'
                    />
                    <FormInput
                      name='fourthMember.school'
                      type='text'
                      placeholder='Škola'
                    />
                    <Select
                      onChange={({ value }) =>
                        setFieldValue('fourthMember.yearOfStudy', value)
                      }
                      name={'fourthMember.yearOfStudy'}
                      items={yearOfStudy}
                      fullWidth
                    />
                    <FormInput
                      name='fourthMember.phoneNumber'
                      type='text'
                      placeholder='Broj telefona'
                    />
                    <FormInput
                      name='fourthMember.email'
                      type='email'
                      placeholder='Mejl'
                    />{' '}
                  </div>
                )}

                {/* team */}
                <h3 className={styles.clanoviLabel}>Ime tima </h3>
                <FormInput name='teamName' type='text' placeholder='Ime tima' />

                <h3 className={styles.clanoviLabel}>
                  Kako ste saznali za Hakaton za srednjoškolce?
                </h3>
                <div className={styles.multipleChoice}>
                  <div className={styles.optionsWrapper}>
                    <input
                      checked={sources.includes('social')}
                      onChange={(e) =>
                        handleSelectSrc('social', e.target.checked)
                      }
                      type='checkbox'
                      name='firstOption'
                    />
                    <label htmlFor='firstOption'>Preko društvenih mreža</label>
                  </div>
                  <div className={styles.optionsWrapper}>
                    <input
                      checked={sources.includes('friends')}
                      onChange={(e) =>
                        handleSelectSrc('friends', e.target.checked)
                      }
                      type='checkbox'
                      name='secondOption'
                    />
                    <label htmlFor='secondOption'>Preko prijatelja</label>
                  </div>
                  <div className={styles.optionsWrapper}>
                    <input
                      checked={sources.includes('teacher')}
                      onChange={(e) =>
                        handleSelectSrc('teacher', e.target.checked)
                      }
                      type='checkbox'
                      name='thirdOption'
                    />
                    <label htmlFor='thirdOption'>Preko profesora</label>
                  </div>
                  <div className={styles.optionsWrapper}>
                    <input
                      checked={sources.includes('tv')}
                      onChange={(e) => handleSelectSrc('tv', e.target.checked)}
                      type='checkbox'
                      name='fourthOption'
                    />
                    <label htmlFor='fourthOption'>Preko televizije</label>
                  </div>
                  <div className={styles.optionsWrapper}>
                    <input
                      checked={sources.includes('portal')}
                      onChange={(e) =>
                        handleSelectSrc('portal', e.target.checked)
                      }
                      type='checkbox'
                      name='fifthOption'
                    />
                    <label htmlFor='fifthOption'>Preko portala</label>
                  </div>
                </div>

                <TextArea
                  label={
                    'Da li ste nekada učestvovali u takmičenjima u programiranju? Ako jeste opišite iskustva'
                  }
                  name='experience'
                  placeholder='Opiši svoje iskustvo'
                ></TextArea>
                <TextArea
                  label={
                    'Navedite koje ste tehnologije, programske jezike i alate koristili do sada? Gde ste ih koristili? Koje biste tehnologije koristili na hakatonu?'
                  }
                  name='technologies'
                  placeholder='Opiši svoje iskustvo'
                ></TextArea>
                <TextArea
                  label={'Zašto baš vaš tim?'}
                  name='whyYou'
                  placeholder='Opiši svoje iskustvo'
                ></TextArea>
                <TextArea
                  label={
                    'Šta biste uradili ako primetite da se član vašeg tima oseća demotivisano ili izgubljenotokom takmičenja?'
                  }
                  name='situational'
                  placeholder='Opiši svoje iskustvo'
                ></TextArea>
                <TextArea
                  label={'Šta biste hteli da postignete tokom takmičenja?'}
                  name='goals'
                  placeholder='Opiši svoje iskustvo'
                ></TextArea>

                <h3 className={styles.clanoviLabel}>
                  Opišite svakog člana tima u 3 reči!{' '}
                </h3>
                <FormInput
                  name='teamMembersDescription'
                  type='text'
                  placeholder='Opis'
                />
                <div className={styles.terms}>
                  <input
                    type='checkbox'
                    name='agreeTerms'
                    checked={isChecked}
                    onChange={() => setisChecked(!isChecked)}
                  />
                  <label htmlFor='agreeTerms'>
                    Saglasni smo da smo pročitali i da smo upoznati sa{' '}
                    <a href={Pravilnik} target='_blank'>
                      pravilnikom takmičenja
                    </a>
                  </label>
                </div>

                <br />
                <Button
                  disabled={!isChecked || !isValid || sources.length === 0}
                  className={
                    isChecked && isValid && sources.length !== 0
                      ? ''
                      : `${styles.disabled}`
                  }
                  color='primary'
                  type={'submit'}
                >
                  {issubmitting ? (
                    <BarLoader color='#fff' />
                  ) : (
                    'POŠALJI PRIJAVU'
                  )}
                </Button>
                {isError && 'Greska prilikom slanja'}

                <br />
              </Form>
            )}
          </Formik>
        </motion.div>
      </div>
      {isComplete && <PopUp message={'Prijava je uspešno poslata!'} />}
    </PageSection>
  );
};

export default FormSection;
