import styles from './Footer.module.css';
import { FaLinkedinIn, FaFacebookF, FaTiktok } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { GrInstagram } from 'react-icons/gr';
import { TfiWorld } from 'react-icons/tfi';
import logo from '../../images/beli logo FONIS 1.png';

const Footer = () => {
  return (
    <footer className={styles.futer}>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.levo}>
          <div className={styles.website}>
            <TfiWorld className={styles.websiteLogo} />
            <a href='https://fonis.rs/'>fonis.rs</a>
          </div>
          <div className={styles.socialMedia}>
            <a
              href='https://www.instagram.com/hakaton_za_srednjoskolce/'
              target='_blank'
            >
              <GrInstagram className='icons instagram' />
            </a>
            <a href='https://www.facebook.com/fonis.rs/' target='_blank'>
              <FaFacebookF className='icons facebook' />
            </a>
            <a href='https://www.linkedin.com/company/fonis/' target='_blank'>
              <FaLinkedinIn className='icons linkedin' />
            </a>
            <a
              href='https://www.tiktok.com/@hakaton_za_srednjoskolce/'
              target='_blank'
            >
              <FaTiktok className='icons tiktok' />
            </a>
          </div>
        </div>
        <img
          onClick={() => window.location.replace('https://fonis.rs')}
          src={logo}
          alt=''
          className={styles.logo}
        />
        <div className={styles.desno}>
          <a href='https://maps.app.goo.gl/gEKMcY6LzzPUh5zH6'>
            Fakultet organizacionih nauka <br className={styles.break} /> Jove
            Ilića 154, Beograd
          </a>
          <FaLocationDot className={styles.location} target='_blank' />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
