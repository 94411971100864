import PageSection from '../PageSection/PageSection';
import styles from './Partners.module.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import logo1 from './logo1.png';
import bestNis from './BEST Nis logo.png';
import React, { forwardRef } from 'react';
import { useState } from 'react';
import PartnersSlider from '../PartnersSlider/PartnersSlider';

import FiscalSolutions from './images/FiscalSolutions.png';

import comingSoon from './images/comingSoon.png';

const mediaPartnerImages = require.context('./images/media/', true);
const mediaPartnerImagesList = mediaPartnerImages
  .keys()
  .map((image) => mediaPartnerImages(image))
  .map((image) => {
    return { image };
  });

const commodityPartnersImages = require.context('./images/commodity', true);
const commodityPartnersImagesList = commodityPartnersImages
  .keys()
  .map((image) => commodityPartnersImages(image))
  .map((image) => ({ image }));

const yearlyPartnersImages = require.context('./images/yearly', true);
const yearlyPartnersImagesList = yearlyPartnersImages
  .keys()
  .map((image) => yearlyPartnersImages(image))
  .map((image) => ({ image }));

const Partners = ({}, partnersRef) => {
  return (
    <PageSection ref={partnersRef} heading='Partneri'>
      <div className={`${styles.wrapper}`}>
        <div className={styles.generalPartner}>
          <p>
            <b>Pokrovitelj takmičenja</b>
          </p>
          <div
            className={styles.imageSingle}
            style={{
              margin: '0 auto',
            }}
          >
            <img
              src={FiscalSolutions}
              alt=''
              className={styles.imageSingleStyle}
            />
          </div>
        </div>

        <PartnersSlider
          title='Godišnji'
          partnersArray={yearlyPartnersImagesList}
        />
        <PartnersSlider
          title='Robni'
          partnersArray={commodityPartnersImagesList}
        />
        <PartnersSlider
          title='Medijski'
          partnersArray={mediaPartnerImagesList}
        />
      </div>
    </PageSection>
  );
};

const yearlyPartners = [
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
];

const commodityPartners = [
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
];

const mediaPartners = [
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
  {
    image: comingSoon,
  },
];

export default forwardRef(Partners);
