export const openingApplications = '2023-11-02T13:00:00';
export const closingApplications = '2023-11-17T00:00:00';
export const finalFinish = '2023-12-10T12:00:00';

export function isApplicationOpened() {
  const today = new Date();

  // return true;

  return (
    today >= new Date(openingApplications) &&
    today <= new Date(closingApplications)
  );
}
