import React, { useLayoutEffect } from 'react';
import FormSection from '../../components/FormSection/FormSection';
import { useScrollToTop } from '../../hooks/scrollToTop';
import { useNavigate } from 'react-router-dom';
import {
  closingApplications,
  isApplicationOpened,
  openingApplications,
} from '../../lib/utils/dates';

const FormPage = () => {
  useScrollToTop();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    const today = new Date();

    if (!isApplicationOpened()) navigate('/');
  }, []);

  return <FormSection />;
};

export default FormPage;
