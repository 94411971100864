import React from 'react'
import styles from "./FormInput.module.css"
import { useField } from 'formik'

const FormInput = ({...props}) => {

  const [field, meta] = useField(props)

  return (
    <>
      <div className={styles.inputWrapper}>
        <input
        {...field}
        {...props}
        className={`${styles.input} ${meta.touched && meta.error ? `${styles.inputError}` : ""}`}
        />
        {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
      </div>
    </>
  )
}

export default FormInput