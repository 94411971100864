import React from 'react';
import styles from './WorkshopsTest.module.css';
import { motion } from 'framer-motion';

const delay = 0.75;

const WorkshopsTest = (props) => {
  return (
    <div
      className={`${styles.wrapper_helper} ${styles.negative} ${
        props.first && styles.first
      } ${props.last && styles.last}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.gray_rectangle}>
          <motion.div
            className={styles.green_line}
            whileInView={{ opacity: 0 }}
            initial={{ opacity: 1 }}
            transition={{ delay: delay, duration: 0.7 }}
            viewport={{ once: true }}
          ></motion.div>

          <motion.div
            whileInView={{ opacity: 0 }}
            initial={{ opacity: 1 }}
            transition={{ delay: delay, duration: 0.7 }}
            viewport={{ once: true }}
            className={styles.animatedHeading}
          >
            <div className={styles.lecturer}>{props.lecturer}</div>
            <div className={styles.workshopName}>{props.main_heading}</div>
          </motion.div>
          <motion.div
            className={styles.circle}
            whileInView={{ x: 120 }}
            initial={{ x: -900 }}
            transition={{ delay: delay, duration: 0.7 }}
            viewport={{ once: true }}
          >
            <img className={styles.image} src={props.picture} alt='Slika'></img>
          </motion.div>

          <motion.div
            className={styles.gray_helper}
            whileInView={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ delay: 1, duration: 0.7 }}
            viewport={{ once: true }}
          >
            <text className={styles.main_heading}>{props.main_heading}</text>
            <p className={styles.paragraph}>{props.paragraph}</p>
          </motion.div>
        </div>
        <motion.div
          className={styles.line}
          whileInView={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: delay + 1.2, duration: 0.5 }}
          viewport={{ once: true }}
        ></motion.div>
      </div>
    </div>
  );
};

export default WorkshopsTest;
