import styles from './AwardsSection.module.css';
import PageSection from '../PageSection/PageSection';

function AwardsSection() {
  return (
    <PageSection heading='Nagrade'>
      <div className={`container ${styles.wrapper}`}>
        <h3 className={styles.subHeading}>U vrednosti od</h3>
        <div className={styles.awards}>
          {/* <div className={styles.comingSoon}>Uskoro...</div> */}
          <div className={styles.box}>
            <div className={styles.awardsContainer}>
              <div className={styles.headingDiv}>
                <h2>1.</h2>
              </div>
              <div>
                <p>70 000 din</p>
              </div>
            </div>
          </div>

          <div className={styles.verticalLine}></div>

          <div className={styles.box}>
            <div
              className={`${styles.awardsContainer} ${styles.secondContainer}`}
            >
              <div className={styles.headingDiv}>
                <h2 className='heading'>2.</h2>
              </div>
              <div>
                <p>50 000 din</p>
              </div>
            </div>
          </div>

          <div className={styles.verticalLine}></div>

          <div className={styles.box}>
            <div className={styles.awardsContainer}>
              <div className={styles.headingDiv}>
                <h2 className='heading'>3.</h2>
              </div>
              <div>
                <p>30 000 din</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
}

export default AwardsSection;
