import React from "react";
import styles from "./NotFound.module.css";

const NotFound = () => {
  return (
    <main className={styles.wrapper}>
      <div className={styles.loader}>
        <h1 className={styles.hzs}>HZS</h1>

        <div className={`${styles.inner} ${styles["one"]}`}></div>
        <div className={`${styles.inner} ${styles["two"]}`}></div>
        <div className={`${styles.inner} ${styles["three"]}`}></div>
      </div>
      <p className={styles.text}>Stranica nije pronađena</p>
    </main>
  );
};

export default NotFound;
