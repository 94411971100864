import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage/Homepage';
import { loadFull } from 'tsparticles';
import Navbar from './components/Navbar/Navbar';
import Menu from './components/Menu/Menu';
import { useCallback, useRef, useState } from 'react';
import Particles from 'react-particles';
import { options } from './lib/utils/particlesOptions';
import Footer from './components/Footer/Footer';
import FormPage from './pages/FormPage/FormPage';
import { AnimatePresence } from 'framer-motion';
import NotFound from './pages/NotFound/NotFound';

function App() {
  const [showMenu, setShowMenu] = useState(false);

  const particlesInit = useCallback(async (engine) => {
    // console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    // await console.log(container);
  }, []);

  const aboutRef = useRef();
  const agendaRef = useRef();
  const workshopsRef = useRef();
  const faqRef = useRef();
  const partnersRef = useRef();

  return (
    <>
      <div className='background-blur'></div>
      <div className='background-overlay'></div>

      <Router>
        <Navbar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          ref={{ aboutRef, agendaRef, workshopsRef, faqRef, partnersRef }}
        />
        <AnimatePresence mode='wait'>
          {showMenu && (
            <Menu
              setShowMenu={setShowMenu}
              ref={{ aboutRef, agendaRef, workshopsRef, faqRef, partnersRef }}
            />
          )}
        </AnimatePresence>
        <Routes>
          <Route
            path='/'
            element={
              <Homepage
                ref={{ aboutRef, agendaRef, workshopsRef, faqRef, partnersRef }}
              />
            }
          />
          <Route path='/prijava' element={<FormPage />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
      <Particles
        style={{ zIndex: -300 }}
        options={options}
        id='tsparticles'
        init={particlesInit}
        loaded={particlesLoaded}
      />
      <Footer />
    </>
  );
}

export default App;
