import Slider from 'react-slick';
import styles from './PartnersSlider.module.css';
import { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

const PartnersSlider = ({ title, partnersArray }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [counter, setCounter] = useState();

  const changeCounter = (next) => {};

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    drag: true,
    speed: 500,
    slidesToShow: partnersArray.length < 5 ? partnersArray.length : 5,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveSlide(next),
    afterChange: (current, next) => changeCounter(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: partnersArray.length < 3 ? partnersArray.length : 3,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: partnersArray.length < 2 ? partnersArray.length : 2,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // console.log(partnersArray);

  return (
    <div className={styles.wrapper}>
      <p>{title}</p>
      <div className={styles.carouselContainer}>
        <Slider {...settings}>
          {partnersArray.map((item, i) => (
            <div key={uuidv4()} className={styles.imageWrapper}>
              <div className={styles.imageSingle}>
                <img
                  src={item.image}
                  alt=''
                  className={styles.imageSingleStyle}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PartnersSlider;
