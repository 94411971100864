import React from 'react';
import Slider from '../Slider/Slider';
import PageSection from '../PageSection/PageSection';

function OrganisationTeam() {
  return (
    <PageSection heading='Organizacioni tim'>
      <Slider />
    </PageSection>
  );
}

export default OrganisationTeam;
