import styles from './PageSection.module.css';
import { forwardRef } from 'react';
function PageSection({heading, children, hideOverflowX, hideOverflowY }, ref) {
  return (
    <section ref={ref}
      className={`${styles.wrapper} ${hideOverflowX && styles.hideOverflowX} ${
        hideOverflowY && styles.hideOverflowY
      }`}
    >
      {heading && <h2 className={styles.heading}>{heading}</h2>}

      {children}
    </section>
  );
}

export default forwardRef( PageSection);
