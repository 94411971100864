import React, { forwardRef, useState } from 'react';
import styles from './Navbar.module.css';
import logo from '../../img/Logo 1.png';
import { FiMenu } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';

import Pravilnik from '../../pdf/Pravilnik.pdf';

function Navbar(
  { showMenu, setShowMenu },
  { aboutRef, agendaRef, workshopsRef, faqRef, partnersRef }
) {
  const navigate = useNavigate();
  const location = useLocation();

  function toggleMenu() {
    setShowMenu(function (previous) {
      return !previous;
    });
  }
  function handleClick(ref) {
    location.pathname !== '/' && navigate('/');
    ref.current?.scrollIntoView();
  }

  return (
    <div className={`container ${styles.wrapper}`}>
      <img
        src={logo}
        onClick={() => {
          setShowMenu(false);
          navigate('/');
        }}
        alt=''
      />

      <nav>
        <ul>
          <li>
            <a onClick={() => handleClick(aboutRef)}>O takmičenju</a>
          </li>
          <li>
            <a onClick={() => handleClick(agendaRef)}>Agenda</a>
          </li>
          <li>
            <a onClick={() => handleClick(workshopsRef)}>Radionice</a>
          </li>
          <li>
            <a onClick={() => handleClick(faqRef)}>Pitanja</a>
          </li>
          <li>
            <a onClick={() => handleClick(partnersRef)}>Partneri</a>
          </li>
          <li>
            <a href={Pravilnik} target='_blank'>
              Pravilnik
            </a>
          </li>
        </ul>
      </nav>

      <motion.div
        whileHover={{
          backgroundColor: '#ffffff47',
          borderRadius: '50%',
          transition: {
            duration: '250ms',
          },
        }}
        whileTap={{ scale: 0.85 }}
        onClick={toggleMenu}
        className={styles.menu}
      >
        {showMenu ? <AiOutlineClose /> : <FiMenu />}
      </motion.div>
    </div>
  );
}

export default forwardRef(Navbar);
