import React, { useState } from 'react';
import PageSection from '../PageSection/PageSection';
import styles from '../Slider/Slider.module.css';
// import {BsChevronLeft, BsChevronRight} from 'react-icons/bs'
// import {FaChevronLeft, FaChevronRight} from 'react-icons/fa6'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi2';

import AnaVicovac from '../../img/OrganisationTeam/Core/AnaVicovac.jpeg';
import PredragStojkovic from '../../img/OrganisationTeam/Core/PredragStojkovic.jpg';
import StefanSedalrevic from '../../img/OrganisationTeam/Core/StefanSedlarevic.png';
import VioletaSekulic from '../../img/OrganisationTeam/Core/VioletaSekulic.jpeg';
import PredragStisovic from '../../img/OrganisationTeam/Core/PredragStisovic.png';
import AndrijaRadovic from '../../img/OrganisationTeam/Core/AndrijaRadovic.jpeg';
import NevenaRadosavljevic from '../../img/OrganisationTeam/Core/NevenaRadosavljevic.jpeg';

import HR from '../../img/OrganisationTeam/HR.jpg';
import Logistika from '../../img/OrganisationTeam/Logistika.jpg';
import PR from '../../img/OrganisationTeam/PR.jpeg';
import Dizajn from '../../img/OrganisationTeam/dizajn.png';
import IT from '../../img/OrganisationTeam/IT.jpeg';
import CR from '../../img/OrganisationTeam/CR.jpg';

import core from '../../img/OrganisationTeam/Core/Core.jpeg';
import { useSwipeable } from 'react-swipeable';
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

function Slider() {
  const [slide, setSlide] = useState(0);

  const teams = [
    {
      imageLeft: AnaVicovac,
      imageRight: core,
      headingLeft: 'Ana Vićovac',
      paragraphLeft: 'Koordinatorka takmičenja',
      headingRight: 'Tim koordinatora',
      paragraphRight:
        'Tim koordinatora ima zadatak da na najefikasniji način upravlja organizacijom ovog takmičenja. Ključna uloga ovog tima jeste baš u predvođenju i motivisanju svih onih koji naporno rade kako bi priredili najbolje iskustvo svim učesnicima. Oni se trude da iskoriste svoje znanje i veštine kako bi ovogodišnji Hakaton za srednjoskolce protekao u najboljem redu.',
    },
    {
      imageLeft: PredragStojkovic,
      imageRight: IT,
      headingLeft: 'Predrag Stojković',
      paragraphLeft: 'Koordinator tima za informacione tehnologije',
      headingRight: 'Tim za informacione tehnologije',
      paragraphRight:
        'Tim za informacione tehnologije je zaslužan za izradu veb-sajta Hakatona za srednjoškolce, koji sadrži sve potrebne informacije o ovom takmičenju i preko kog možete poslati vašu prijavu.',
    },
    {
      imageLeft: NevenaRadosavljevic,
      imageRight: Dizajn,
      headingLeft: 'Nevena Radosavljević',
      paragraphLeft: 'Koordinatorka tima za dizajn',
      headingRight: 'Tim za dizajn',
      paragraphRight:
        'Tim za dizajn je, zajedno sa timom za odnose s javnošću, svoju kreativnost usmerio na osmišljavanje vizuelnog identiteta Hakatona za srednjoškolce. Pored lepog izgleda ovog veb-sajta, svoje veštine pokazuju i u izradi vizuala za društvene mreže, čiji je cilj da na najzanimljiviji način prenesu informacije o ovom takmičenju.',
    },
    {
      imageLeft: PredragStisovic,
      imageRight: PR,
      headingLeft: 'Predrag Stišović',
      paragraphLeft: 'Koordinator tima za odnose s javnošću',
      headingRight: 'Tim za odnose s javnošću',
      paragraphRight:
        'Tim za odnose s javnošću, osim osmišljvanja vizuelnog identiteta u saradnji sa timom za dizajn, svoj trud ulaže u sklapanje medijskih saradnji i širenje glasa o takmičenju. Oni naporno rade kako bi proširili vest o Hakatonu na što bolji način, što većem broju ljudi.',
    },
    {
      imageLeft: StefanSedalrevic,
      imageRight: CR,
      headingLeft: 'Stefan Sedlarević',
      paragraphLeft: 'Koordinator tima za akademske i odnose sa kompanijama',
      headingRight: 'Tim za akademske i odnose sa kompanijama',
      paragraphRight:
        'Tim za akademske i odnose sa kompanijama predano radi na sklapanju i održavanju partnerstava sa brojnim kompanijama koje nam pružaju podršku u ostvarenju Hakatona za srednjoškolce. Uz to, oni sarađuju sa stručnim mentorima i žirijem, kako bi svima obezbedili nezaboravno iskustvo.',
    },
    {
      imageLeft: AndrijaRadovic,
      imageRight: Logistika,
      headingLeft: 'Andrija Radović',
      paragraphLeft: 'Koordinator tima za logistiku',
      headingRight: 'Tim za logistiku',
      paragraphRight:
        'Tim za logistiku se brine o obezbeđivanju prostora za takmičenje, kao i osmišljavanju operacionog plana čitavog takmičenja. Oni će dati sve od sebe da učine da se svi takmičari osete prijatno i ovo druženje pamte kao jednu lepu uspomenu.',
    },
    {
      imageLeft: VioletaSekulic,
      imageRight: HR,
      headingLeft: 'Violeta Sekulić',
      paragraphLeft: 'Koordinatorka tima za ljudske resurse',
      headingRight: 'Tim za ljudske resurse',
      paragraphRight:
        'Tim za ljudske resurse je zadužen za izbor najboljih timova kroz selekciju. Pored toga, oni komuniciraju sa učesnicima i tu su da odgovore na sva pitanja. Takođe se fokusiraju na jačanje odnosa unutar organizacionih timova i stvaranju što bolje i prijatnije radne atmosfere.',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      toggleRight();
    }, 10000);

    return () => clearInterval(interval);
  }, [slide]);

  function toggleLeft() {
    setSlide(function (previous) {
      if (previous <= 0) {
        return (previous = teams.length - 1);
      }
      return previous - 1;
    });
  }

  function toggleRight() {
    setSlide(function (previous) {
      if (previous >= teams.length - 1) {
        return (previous = 0);
      }
      return previous + 1;
    });
  }

  const handler = useSwipeable({
    onSwipedLeft: toggleRight,
    onSwipedRight: toggleLeft,
  });

  const rightHandler = useSwipeable({
    onSwipedLeft: toggleRight,
    onSwipedRight: toggleLeft,
  });

  return (
    <PageSection>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.bottom}>
          <div className={styles.leftSide}>
            <div className={styles.leaderSide}>
              <button onClick={toggleLeft}>
                <motion.div
                  className={styles.arrow}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.85 }}
                >
                  <HiChevronLeft />
                </motion.div>
              </button>

              {/* <div {...handler} className={styles.containerLeftImg}>
                <AnimatePresence mode='wait'>
                <motion.img
                initial={{opacity:0}}
                animate={{opacity:1}}
                exit={{opacity:0}}
                src={teams[slide].imageLeft} alt='' />
                </AnimatePresence>
              </div> */}
              <AnimatePresence mode='popLayout' initial={false}>
                {teams.map((team, i) => {
                  return (
                    i === slide && (
                      <motion.div
                        {...handler}
                        className={styles.containerLeftImg}
                        key={uuidv4()}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <img src={team.imageLeft} alt='' />
                      </motion.div>
                    )
                  );
                })}
              </AnimatePresence>

              <button onClick={toggleRight}>
                <motion.div
                  className={styles.arrow}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.85 }}
                >
                  <HiChevronRight />
                </motion.div>
              </button>
            </div>

            <div className={styles.textLeft}>
              <h4>{teams[slide].headingLeft}</h4>
              <p>{teams[slide].paragraphLeft}</p>
            </div>
          </div>
          <div className={styles.rightSide}>
            <AnimatePresence mode='popLayout' initial={false}>
              {teams.map((team, i) => {
                return (
                  <motion.div
                    {...rightHandler}
                    style={{ display: i === slide ? 'block' : 'none' }}
                    className={styles.containerRightImg}
                    key={uuidv4()}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <img src={teams[slide].imageRight} alt='' />
                  </motion.div>
                );
              })}
            </AnimatePresence>
            <div className={styles.textRight}>
              <h4>{teams[slide].headingRight}</h4>
              <p>{teams[slide].paragraphRight}</p>
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
}

export default Slider;
