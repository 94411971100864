import { BiDownArrow } from "react-icons/bi";
import styles from "./Accordion.module.css";
import { useRef } from "react";

const Accordion = ({ title, content, active, setActive }) => {
  const handleClick = () => {
    setActive(active === title ? null : title);
  };

  const contentRef = useRef();

  return (
    <div className={styles.accordion} onClick={handleClick}>
      <div className={styles.accordionHeading}>
        <p>{title}</p>
        <span
          className={styles.iconWrapper}
          style={{
            transform: active === title ? "rotateX(180deg)" : "rotateX(0)",
            transition: "all 250ms ease",
          }}
        >
          {" "}
          <BiDownArrow />
        </span>
      </div>

      <div
        style={{
          height: active === title ? contentRef.current.scrollHeight : 0,
        }}
        ref={contentRef}
        className={styles.accordionContent}
      >
        <p className={styles.container2}>{content}</p>
      </div>
    </div>
  );
};

export default Accordion;
