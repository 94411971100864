import * as yup from 'yup';

export const formSchema = yup.object().shape({
  firstMember: yup.object().shape({
    name: yup
      .string()
      .required('Ime je obavezno')
      .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravno ime')
      .trim(),
    city: yup
      .string()
      .required('Naziv grada je obavezan')
      .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravan naziv grada')
      .trim(),
    school: yup
      .string()
      .required('Naziv škole je obavezan')
      .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravan naziv škole')
      .trim(),
    phoneNumber: yup
      .string()
      .required('Broj telefona je obavezan')
      .matches(/\+(38)[0-9]{6,15}/, 'Mora biti u formatu +38*********'),
    email: yup
      .string()
      .email('Unesite ispravnu email adresu.')
      .required('Email je obavezan'),
  }),

  secondMember: yup.object().shape({
    name: yup
      .string()
      .required('Ime je obavezno')
      .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravno ime')
      .trim(),
    city: yup
      .string()
      .required('Naziv grada je obavezan')
      .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravan naziv grada')
      .trim(),
    school: yup
      .string()
      .required('Naziv škole je obavezan')
      .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravan naziv škole')
      .trim(),
    phoneNumber: yup
      .string()
      .required('Broj telefona je obavezan')
      .matches(/\+(38)[0-9]{6,15}/, 'Mora biti u formatu +38*********')
      .trim(),
    email: yup
      .string()
      .email('Unesite ispravnu email adresu.')
      .required('Email je obavezan')
      .trim(),
  }),

  thirdMember: yup.object().shape({
    name: yup
      .string()
      .required('Ime je obavezno')
      .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravno ime')
      .trim(),
    city: yup
      .string()
      .required('Naziv grada je obavezan')
      .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravan naziv grada')
      .trim(),
    school: yup
      .string()
      .required('Naziv škole je obavezan')
      .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravan naziv škole')
      .trim(),
    phoneNumber: yup
      .string()
      .required('Broj telefona je obavezan')
      .matches(/\+(38)[0-9]{6,15}/, 'Mora biti u formatu +38*********')
      .trim(),
    email: yup
      .string()
      .email('Unesite ispravnu email adresu.')
      .required('Email je obavezan')
      .trim(),
  }),

  fourth: yup.boolean().default(false),

  fourthMember: yup
    .object()
    .shape({
      name: yup.string(),
      city: yup.string(),
      school: yup.string(),
      yearOfStudy: yup.string(),
      phoneNumber: yup.string(),
      email: yup.string().email('Invalid email address'),
    })
    .nullable()
    .when('fourth', {
      is: true,
      then: () =>
        yup.object({
          name: yup
            .string()
            .required('Ime je obavezno')
            .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravno ime')
            .trim(),
          city: yup
            .string()
            .required('Naziv grada je obavezan')
            .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravan naziv grada')
            .trim(),
          school: yup
            .string()
            .required('Naziv škole je obavezan')
            .matches(/[a-žA-Žа-џЂ-Ш]/g, 'Unesite ispravan naziv škole')
            .trim(),
          phoneNumber: yup
            .string()
            .required('Broj telefona je obavezan')
            .matches(/\+(38)[0-9]{6,15}/, 'Mora biti u formatu +38*********')
            .trim(),
          email: yup
            .string()
            .email('Unesite ispravnu email adresu.')
            .required('Email je obavezan')
            .trim(),
        }),
    }),

  leadSource: yup.array().of(yup.string()),
  experience: yup.string().required('Obavezno polje'),
  teamName: yup.string().required('Obavezno polje'),
  technologies: yup.string().required('Obavezno polje'),
  whyYou: yup.string().required('Obavezno polje'),
  situational: yup.string().required('Obavezno polje'),
  goals: yup.string().required('Obavezno polje'),
  teamMembersDescription: yup.string().required('Obavezno polje'),
});
