import React, { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import styles from './Timer.module.css';
import {
  closingApplications,
  finalFinish,
  openingApplications,
} from '../../lib/utils/dates';

const Timer = () => {
  const { totalSeconds, seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(finalFinish),

    // onExpire: () => console.warn('onExpire called'),
  });

  return (
    <div className={styles.wrapper}>
      <text id={styles.naslov}>DO KRAJA FINALA</text>

      <div className={styles.timer}>
        {days > 0 && (
          <>
            <div className={styles.number_container}>
              <text className={`${styles.numbers} ${styles.days}`}>
                {days < 10 ? '0' + days : days}
              </text>
            </div>

            <div className={styles.vline}></div>
          </>
        )}

        <div className={styles.number_container}>
          <text className={`${styles.numbers} ${styles.hours}`}>
            {hours < 10 ? '0' + hours : hours}
          </text>
        </div>

        <div className={styles.vline}></div>

        <div className={styles.number_container}>
          <text className={`${styles.numbers} ${styles.minutes}`}>
            {minutes < 10 ? '0' + minutes : minutes}
          </text>
        </div>

        <div className={styles.vline}></div>

        <div className={styles.number_container}>
          <text className={`${styles.numbers} ${styles.seconds}`}>
            {seconds < 10 ? '0' + seconds : seconds}
          </text>
        </div>
      </div>
    </div>
  );
};

export default Timer;
