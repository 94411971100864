export const options = {
  fullScreen: true,
  fpsLimit: 60,
  background: {
    color: 'transparent',
  },
  interactivity: {
    events: {
      onClick: {
        enable: false,
        mode: 'push',
      },
      onHover: {
        enable: false,
        mode: 'repulse',
      },
      resize: true,
    },
    modes: {
      bubble: {
        distance: 400,
        duration: 200,
        opacity: 0.2,
        size: 35,
      },
      push: {
        quantity: 4,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: '#ffffff',
    },
    links: {
      color: '#ffffff',
      distance: 150,
      enable: false,
      opacity: 0.15,
      width: 8,
    },
    collisions: {
      enable: false,
    },
    move: {
      direction: 'none',
      enable: true,
      outMode: 'bounce',
      random: false,
      speed: 0.2,
      straight: false,
    },
    number: {
      density: {
        enable: false,
        value_area: 1000,
      },
      value: 500,
    },
    opacity: {
      value: 0.15,
    },
    shape: {
      type: 'circle',
    },
    size: {
      random: false,
      value: { min: 1, max: 2 },
    },
    detectRetina: true,
  },
};
