import React from 'react';
import Timer from '../../components/Timer/Timer';
import { Experiance } from '../../components/Experiance/Experiance';
import FAQ from '../../components/FAQ/FAQ';
import Agenda from '../../components/Agenda/Agenda';
import Hero from '../../components/Hero/Hero';
import OrganisationTeam from '../../components/OrganisationTeam/OrganisationTeam';
import AwardsSection from '../../components/AwardsSection/AwardsSection';
import Motivation from '../../components/Motivation/Motivation';
import AboutCompetition from '../../components/AboutCompetition/AboutCompetition';
import Partners from '../../components/Partners/Partners';
import WorkshopsSection from '../../components/WorkshopsSection/WorkshopsSection';
import { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useScrollToTop } from '../../hooks/scrollToTop';

const Homepage = (
  {},
  { aboutRef, agendaRef, workshopsRef, faqRef, partnersRef }
) => {
  useScrollToTop();
  return (
    <>
      <Hero />
      <Timer />
      <AwardsSection />
      <AboutCompetition ref={aboutRef} />
      <Agenda ref={agendaRef} />
      <WorkshopsSection ref={workshopsRef} />
      <Experiance />
      <FAQ ref={faqRef} />
      <OrganisationTeam />
      <Partners ref={partnersRef} />
      <Motivation />
    </>
  );
};

export default forwardRef(Homepage);
