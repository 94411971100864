import React from 'react';
import styles from './Button.module.css';

function ColorButton({
  onClick,
  color = 'primary',
  border,
  fullWidth,
  type,
  children,
  className,
  disabled = false,
}) {
  const getColor = () => {
    return color === 'primary' ? styles.primary : styles.secondary;
  };

  return (
    <button
      className={`${styles.wrapper} ${getColor()} ${
        border && styles.bordered
      } ${fullWidth && styles.fullWidth} ${className} ${
        disabled && styles.disabled
      }`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default ColorButton;
