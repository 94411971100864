import React, { forwardRef, useRef } from 'react';
import Accordion from '../Accordion/Accordion';
import { useState } from 'react';
import styles from './FAQ.module.css';
import PageSection from '../PageSection/PageSection';
import Button from '../Button/Button';
import axios from 'axios';
import { apiURL } from '../../utils/api/url';
import { useEffect } from 'react';

const FAQ = ({}, faqRef) => {
  const [active, setActive] = useState('');

  const [formStatus, setFormStatus] = useState('idle');

  useEffect(() => {
    if (formStatus === 'success') {
      const timeout = setTimeout(() => {
        setFormStatus('idle');
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [formStatus, setFormStatus]);

  const questionRef = useRef();
  const senderRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormStatus('loading');

    const data = {
      question: questionRef.current.value,
      sender: senderRef.current.value,
    };

    try {
      axios.post(`${apiURL}/api/mail`, data);

      setFormStatus('success');
    } catch (error) {
      // console.log(error);
      setFormStatus('error');
    }
  };

  return (
    <PageSection ref={faqRef} heading='Pitanja'>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.accordionsContainer}>
          {data.map((item, i) => (
            <Accordion
              title={item.question}
              content={item.answer}
              active={active}
              setActive={setActive}
            />
          ))}
        </div>
      </div>

      <div className='container'>
        <div className={`${styles.container}`}>
          <div className={styles.headingContainer}>
            <p className={styles.heading}>Postavite nam pitanje</p>
            <hr className={styles.hr} />
          </div>

          <form className={styles.form} onSubmit={handleSubmit}>
            <p>Mejl</p>
            <input
              className={styles.input}
              type='email'
              placeholder=''
              name='user_email'
              required
              ref={senderRef}
              style={{ width: '100%' }}
            />

            <p>Pitanje</p>
            <textarea
              name='message'
              cols={30}
              rows={10}
              required
              className={styles.textarea}
              ref={questionRef}
            ></textarea>

            <Button
              type='submit'
              color={formStatus === 'success' ? 'secondary' : 'primary'}
              fullWidth
              disabled={formStatus === 'loading' || formStatus === 'success'}
            >
              {formStatus === 'idle'
                ? 'Pošalji'
                : formStatus === 'loading'
                ? 'Šalje se...'
                : formStatus === 'success'
                ? 'Uspešno'
                : 'Greška pri slanju, probaj ponovo'}
            </Button>
          </form>
        </div>
      </div>
    </PageSection>
  );
};

const data = [
  {
    question: 'Kako učestvovati na Hakatonu?',
    answer:
      'Prvi krug selekcije jeste pisanje i slanje prijave preko veb-sajta. Bitno je da se tim potrudi da se tom prijavom izdvoji i ostavi što bolji utisak. Zatim sledi onlajn test sa ciljem da se proveri znanje iz oblasti informacionih tehnologija. Za najbolje plasirane timove slede radionice koje pripremaju učesnike za poslednju fazu selekcije, a potom domaći zadatak, koji određuje da li će tim učestovati u finalu Hakatona.',
  },
  {
    question:
      'Da li postoji određena tehnologija iz koje je neophodno imati predznanje?',
    answer:
      'Neophodno je imati predznanje iz informacionih tehnologija, zbog procesa selekcije gde će se testirati znanje svakog od članova tima. Na takmičenju ne uslovljavamo korišćenje nijedne striktne tehnologije. Potrebno je da razvijete softversko rešenje na zadati problem, a potom i da ga prezentujete pred stručnim žirijem.',
  },
  {
    question: 'Koje tehnologije mogu da se koriste za vreme Hakatona?',
    answer:
      'Takmičari imaju prava da koriste tehnologiju po želji, internet, kao i bilo koju dodatnu literaturu za koju smatraju da im može pomoći i olakšati rad na takmičenju.',
  },
  {
    question: 'Da li postoje neka ograničenja prilikom sastavljanja timova?',
    answer:
      'Jedan tim treba da se sastoji od tri do četiri člana i učesnici moraju biti učenici srednjih škola Republike Srbije. Osim ovoga, ne postoje nikakva druga ograničenja. Takmičari koji su u istom timu mogu biti iz različitih srednjih škola ili različitog godišta, samo da ispunjavaju prethodno navedene uslove.',
  },
  {
    question: 'Da li će takmičari imati mentora za rad na takmičenju?',
    answer:
      'Kako bi takmičari mogli da ostvare pun potencijal svojih ideja, svakom timu biće dodeljen po jedan stručni mentor koji će im davanjem saveta i sugestija pomoći u tome.',
  },
  {
    question: 'Kako se odlučuje ko će pobediti?',
    answer:
      'Svaki tim ima 10 minuta za predstavljanje svoje ideje i finalnog rešenja stručnom žiriju, nakon čega žiri ima 5 minuta za dodatna pitanja. Posle završenih prezentacija svih timova, žiri određuje pobednike takmičenja.',
  },
];

export default forwardRef(FAQ);
