import { useNavigate } from 'react-router';
import { motion } from 'framer-motion';

import Button from '../Button/Button';

import styles from './PopUp.module.css';

const PopUp = ({ message }) => {
  const navigate = useNavigate();

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 1 }}
        className={styles.backdrop}
      ></motion.div>
      <div className={`${styles.wrapper} container`}>
        <motion.div
          initial={{ opacity: 0, y: '120px' }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.7,
            type: 'tween',
            duration: 1,
            ease: 'easeInOut',
          }}
          className={styles.message}
        >
          {message}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: '100px' }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          <Button onClick={() => navigate('/')}>
            Vrati se na početnu stranu
          </Button>
        </motion.div>
      </div>
    </>
  );
};

export default PopUp;
