import React from 'react'
import styles from "./TextArea.module.css"
import { useField } from 'formik'

const TextArea = ({label, ...props}) => {

  const [field, meta] = useField(props)

  return (
    <>
      <div className={styles.textAreaWrapper}>
        <h3 className={styles.label}>{label}</h3>
        <hr className={styles.hr} />
        <textarea 
        {...field}
        {...props}
        className={`${styles.area} ${meta.touched && meta.error ? `${styles.areaError}` : ""}`}
        ></textarea>

        {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}

      </div>
    </>
  )
}

export default TextArea