import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './Select.module.css';
import { IoIosArrowDown } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

const Select = ({
  label,
  name,
  items,
  onChange,
  fullWidth,
  error,
}) => {
  const [selected, setSelected] = useState(items[0] ?? { value: '', name: '' });
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    onChange(selected)
  }, [selected]);

  const handleItemClick = (item) => {
    setSelected({ ...item });
    setIsOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      layout
      className={
        fullWidth
          ? `${styles['input-container']} ${styles['fullWidth']}`
          : styles['input-container']
      }
    >
      <label className={styles.label} >
        {label}
      </label>
      <input
        id={name}
        type='text'
        value={selected.value}
        readOnly
        style={{ display: 'none' }}
        name={name}
      />
      <div
        className={styles.select}
        name={name}
        onChange={onChange}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.displayValue}>{selected.name}</div>
        <div className='flex flex-center'>
          <IoIosArrowDown
            style={{
              rotate: isOpen ? '180deg' : '0deg',
              transition: 'all 250ms ease',
            }}
          />
        </div>
      </div>
      <AnimatePresence mode='sync'>
        {isOpen && (
          <motion.div
            initial={{ x: '-100px', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '100px', opacity: 0 }}
            className={styles.menu}
          >
            {items &&
              items.length &&
              items.map((item) => (
                <div
                  key={uuidv4()}
                  onClick={() => handleItemClick(item)}
                  className={styles.menuItem}
                >
                  {item.name}
                </div>
              ))}
          </motion.div>
        )}
      </AnimatePresence>
      <p className={styles.error}>{error?.message}</p>
    </motion.div>
  );
};

export default Select;
